import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Footer.css'; // Make sure to add your CSS file for styling

function Footer() {
  return (
    <footer className="footer">
      <Link to="/" className="home-link">
        Home
      </Link>
    </footer>
  );
}

export default Footer;
