import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import '../css/EmergencyFund.css';

// Register necessary Chart.js components
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

function EmergencyFund() {
  const [categories, setCategories] = useState(['']);
  const [amounts, setAmounts] = useState(['']);
  const [salary, setSalary] = useState('');
  const [error, setError] = useState('');
  const [isCalculated, setIsCalculated] = useState(false);
  const [selectedFund, setSelectedFund] = useState(null);

  const handleCategoryChange = (index, value) => {
    const newCategories = [...categories];
    newCategories[index] = value;
    setCategories(newCategories);
    validateCategories(newCategories);
  };

  const handleAmountChange = (index, value) => {
    const newAmounts = [...amounts];
    newAmounts[index] = value;
    setAmounts(newAmounts);
  };

  const handleSalaryChange = (e) => {
    setSalary(e.target.value);
  };

  const handleAddCategory = () => {
    if (categories.length < 10) {
      const newCategories = [...categories, ''];
      const newAmounts = [...amounts, ''];
      setCategories(newCategories);
      setAmounts(newAmounts);
    }
  };

  const validateCategories = (categoriesList) => {
    const uniqueCategories = new Set(categoriesList);
    if (uniqueCategories.size !== categoriesList.length) {
      setError('Categories must be unique');
    } else {
      setError('');
    }
  };

  const handleCalculateClick = () => {
    if (salary === '' || categories.some(cat => cat === '') || amounts.some(amount => amount === '')) {
      setError('Please fill out all fields');
    } else {
      setIsCalculated(true);
      setError('');
    }
  };

  const totalExpenses = amounts.reduce((acc, val) => acc + parseFloat(val) || 0, 0);
  const threeTimesIncome = 3 * salary;
  const sixTimesIncome = 6 * salary;
  const threeTimesExpenses = 3 * totalExpenses;
  const sixTimesExpenses = 6 * totalExpenses;

  let emergencyFundOptions = [
    { title: '3x Income', value: threeTimesIncome, description: 'Three months of your monthly income.' },
    { title: '3x Expenses', value: threeTimesExpenses, description: 'Three months of your total expenses.' },
    { title: '6x Income', value: sixTimesIncome, description: 'Six months of your monthly income.' },
    { title: '6x Expenses', value: sixTimesExpenses, description: 'Six months of your total expenses.' }
  ];

  // Sort the options from least to highest
  emergencyFundOptions.sort((a, b) => a.value - b.value);

  useEffect(() => {
    // Auto-select the plan with the least amount
    setSelectedFund(0);
  }, [isCalculated]);

  const handleFundSelection = (index) => {
    setSelectedFund(index);
  };

  const chartData = {
    labels: emergencyFundOptions.map(option => option.title),
    datasets: [
      {
        label: 'Emergency Fund Options',
        data: emergencyFundOptions.map(option => option.value),
        backgroundColor: emergencyFundOptions.map((_, index) => index === selectedFund ? '#FF6384' : '#36A2EB'),
        borderColor: '#fff',
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true, // Start from zero
        title: {
          display: true,
          text: 'Amount'
        }
      }
    }
  };

  return (
    <div className="emergency-fund-page">
      <h2>Emergency Fund Calculator</h2>
      <form className="emergency-fund-form">
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category, index) => (
              <tr key={index}>
                <td>
                  <select
                    value={category}
                    onChange={(e) => handleCategoryChange(index, e.target.value)}
                    className="input-select"
                  >
                    <option value="">Select a category</option>
                    <option value="Rent/Mortgage">Rent/Mortgage</option>
                    <option value="Utilities">Utilities</option>
                    <option value="Groceries">Groceries</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Transportation">Transportation</option>
                    <option value="Others">Others</option>
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    value={amounts[index]}
                    onChange={(e) => handleAmountChange(index, e.target.value)}
                    min="0"
                    className="input-number"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="button" onClick={handleAddCategory} className="add-category-button">
          Add More
        </button>
        <div className="salary-input-section">
          <label htmlFor="monthly-salary" className="salary-label"><b>Monthly Salary</b></label>
          <input
            type="number"
            id="monthly-salary"
            value={salary}
            onChange={handleSalaryChange}
            min="0"
            className="input-number salary-input"
            placeholder="Enter your monthly salary"
          />
        </div>
        <button type="button" onClick={handleCalculateClick} className="calculate-button">
          Calculate Emergency Fund
        </button>
        {error && <div className="error-message">{error}</div>}
        
      </form>
      {isCalculated && (
        <>
          <div className="emergency-fund-options">
            {emergencyFundOptions.map((option, index) => (
              <div key={index} className={`fund-card ${index === selectedFund ? 'selected' : ''}`}>
                {index === 0 && <div className="recommended-banner">Recommended</div>}
                <input
                  type="radio"
                  id={`fund-option-${index}`}
                  name="fund-option"
                  checked={index === selectedFund}
                  onChange={() => handleFundSelection(index)}
                />
                <label htmlFor={`fund-option-${index}`}>
                  <h3>{option.title}</h3>
                  <p>${option.value.toFixed(2)}</p>
                  <p>{option.description}</p>
                </label>
              </div>
            ))}
          </div>
          <div className="chart-container">
            <Bar data={chartData} options={chartOptions} />
          </div>
        </>
      )}
    </div>
  );
}

export default EmergencyFund;
