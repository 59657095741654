import React, { useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import '../css/SpendAnalyzer.css';

ChartJS.register(Title, Tooltip, Legend, ArcElement, ChartDataLabels);

function SpendAnalyzer() {
  const [categories, setCategories] = useState(['']);
  const [amounts, setAmounts] = useState(['']);
  const [error, setError] = useState('');
  const [isAnalyzed, setIsAnalyzed] = useState(false);

  const handleCategoryChange = (index, value) => {
    const newCategories = [...categories];
    newCategories[index] = value;
    setCategories(newCategories);
    validateCategories(newCategories);
  };

  const handleAmountChange = (index, value) => {
    const newAmounts = [...amounts];
    newAmounts[index] = value;
    setAmounts(newAmounts);
  };

  const handleAddCategory = () => {
    if (categories.length < 10) {
      const newCategories = [...categories, ''];
      const newAmounts = [...amounts, ''];
      setCategories(newCategories);
      setAmounts(newAmounts);
    }
  };

  const validateCategories = (categoriesList) => {
    const uniqueCategories = new Set(categoriesList);
    if (uniqueCategories.size !== categoriesList.length) {
      setError('Categories must be unique');
    } else {
      setError('');
    }
  };

  const handleAnalyzeClick = () => {
    if (categories.some(cat => cat === '') || amounts.some(amount => amount === '')) {
      setError('Please fill out all fields');
    } else {
      setIsAnalyzed(true);
      setError('');
    }
  };

  const totalAmount = amounts.reduce((acc, val) => acc + parseFloat(val) || 0, 0);

  const uniqueCategories = [...new Set(categories)];
  const categoryMap = new Map();
  uniqueCategories.forEach((category, index) => {
    if (category && amounts[index]) {
      categoryMap.set(category, parseFloat(amounts[index]) || 0);
    }
  });

  const spendingData = {
    labels: [...categoryMap.keys()],
    datasets: [
      {
        label: 'Spending Breakdown',
        data: [...categoryMap.values()],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#D0D0D0', '#C0C0C0', '#FF9F40', '#4BC0C0', '#FFCE56', '#36A2EB'],
        borderColor: '#fff',
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#333',
          filter: (item) => categoryMap.has(item.text), // Filter out unselected categories
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            const percentage = totalAmount > 0 ? ((value / totalAmount) * 100).toFixed(0) : 0;
            return `${tooltipItem.label}: ${value} (${percentage}%)`;
          },
        },
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
      },
      datalabels: {
        formatter: (value, context) => {
          const percentage = totalAmount > 0 ? ((value / totalAmount) * 100).toFixed(0) : 0;
          return `${percentage}%`;
        },
        color: 'black',
        anchor: 'end',
        align: 'center',
        offset: 5,
        font: {
          weight: 'bold',
        },
      },
    },
    elements: {
      arc: {
        borderColor: '#fff',
      },
    },
  };

  const topCategories = Array.from(categoryMap.entries())
    .sort((a, b) => b[1] - a[1])
    .slice(0, 3)
    .map(([category]) => category);

  const recommendations = [
    'Increase savings by 10%',
    `Reduce expenses in the ${topCategories[0] || 'category'} by 10%`,
  ];

  return (
    <div className="spend-analyzer-page">
      <h2>Spend Analyzer</h2>
      <form className="spend-form">
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category, index) => (
              <tr key={index}>
                <td>
                  <select
                    value={category}
                    onChange={(e) => handleCategoryChange(index, e.target.value)}
                    className="input-select"
                  >
                    <option value="">Select a category</option>
                    <option value="Groceries">Groceries</option>
                    <option value="Movies">Movies</option>
                    <option value="Rent">Rent</option>
                    <option value="Debts">Debts</option>
                    <option value="Others">Others</option>
                    <option value="Savings">Savings</option>
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    value={amounts[index]}
                    onChange={(e) => handleAmountChange(index, e.target.value)}
                    min="0"
                    className="input-number"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="button" onClick={handleAddCategory} className="add-category-button">
          Add More
        </button>
        <button type="button" onClick={handleAnalyzeClick} className="analyze-button">
          Analyze My Spends
        </button>
        {error && <div className="error-message">{error}</div>}
      </form>
      {isAnalyzed && (
        <>
          <div className="chart-container">
            <Pie data={spendingData} options={chartOptions} />
          </div>
          <h3>Top 3 Spending Categories</h3>
          <ul>
            {topCategories.map((category, index) => (
              <li key={index}>{category}</li>
            ))}
          </ul>
          <h3>Recommendations</h3>
          <ul>
            {recommendations.map((rec, index) => (
              <li key={index}>{rec}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default SpendAnalyzer;
