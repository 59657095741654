import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import './App.css';
import Login from './components/js/Login';
import SpendAnalyzer from './components/js/SpendAnalyzer';
import Header from './components/js/Header';
import Footer from './components/js/Footer';
import logo from './assets/MitralLogo.png';
import EmergencyFund from './components/js/EmergencyFund'; // Import the new component

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <Header />
      {/* Conditionally Render the Logo on the Home Page */}
      {location.pathname === '/' && (
        <div className="logo-container">
          <img src={logo} alt="Logo" className="app-logo" />
        </div>
      )}
      <Routes>
        <Route
          path="/"
          element={
            <header className="App-header">
              <div className="hero-content">
                <h1 className="hero-title">Personalized Financial Advisor for Everyone</h1>
                <p className="hero-subtitle">Powered by AI</p>
                <Link to="/login">
                  <button className="cta-button">
                    Get Started
                  </button>
                </Link>
              </div>
            </header>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/spend-analyzer" element={<SpendAnalyzer />} />
        <Route path="/emergency-fund" element={<EmergencyFund />} /> {/* New Route */}
      </Routes>
      <Footer />
    </div>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
